// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-bak-js": () => import("./../../../src/pages/index.bak.js" /* webpackChunkName: "component---src-pages-index-bak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy_notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-return-and-cancelation-js": () => import("./../../../src/pages/return_and_cancelation.js" /* webpackChunkName: "component---src-pages-return-and-cancelation-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-truck-01-js": () => import("./../../../src/pages/truck01.js" /* webpackChunkName: "component---src-pages-truck-01-js" */)
}

